// Main Style
@import './layout/variable_mixin_function';

// --------------------------------------------------------

/* Vendor */

// --------------------------------------------------------

/* Component */
.pagebg{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-image: url('../images/index/k_v_bn.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
section {
    position: relative;
    min-height: rem-calc(100);
}
.no-scroll {
    overflow: hidden !important;
}
// --------------------------------------------------------
// /* Cube */ --------------------------------[Start]
// --------------------------------------------------------
.cube-container {
    position: relative;
    perspective: 600px; // distance between the scene and the viewer
    perspective-origin: 50% 50%;
    transition: .3s all ease;
    opacity: 1;

    &.right {
        width: rem-calc(20);
        height: rem-calc(20);
        position: absolute;
        right: rem-calc(20);
        bottom: rem-calc(-50);

        @include breakpoint(xlarge) {
            width: rem-calc(50);
            height: rem-calc(50);
            bottom: rem-calc(-70);
        }

        @include breakpoint(large only) {
            width: rem-calc(36);
            height: rem-calc(36);
            bottom: rem-calc(-50);
        }

        .cube {
            animation: spin2 8s infinite linear;

            figure {
                width: rem-calc(20);
                height: rem-calc(20);

                &.front {
                    transform: translateZ(rem-calc(10));
                }

                &.back {
                    transform: rotateY(180deg) rotateX(0deg) translateZ(rem-calc(10));
                }

                &.left {
                    transform: rotateY(-90deg) translateZ(rem-calc(10));
                }

                &.right {
                    transform: rotateY(90deg) translateZ(rem-calc(10));
                }

                &.top {
                    transform: rotateX(90deg) translateZ(rem-calc(10));
                }

                &.bottom {
                    transform: rotateX(-90deg) rotateZ(-180deg) translateZ(rem-calc(10));
                    opacity: .55;
                }

                @include breakpoint(xlarge) {
                    width: rem-calc(50);
                    height: rem-calc(50);

                    &.front {
                        transform: translateZ(rem-calc(25));
                    }

                    &.back {
                        transform: rotateY(180deg) rotateX(0deg) translateZ(rem-calc(25));
                    }

                    &.left {
                        transform: rotateY(-90deg) translateZ(rem-calc(25));
                    }

                    &.right {
                        transform: rotateY(90deg) translateZ(rem-calc(25));
                    }

                    &.top {
                        transform: rotateX(90deg) translateZ(rem-calc(25));
                    }

                    &.bottom {
                        transform: rotateX(-90deg) rotateZ(-180deg) translateZ(rem-calc(25));
                    }
                }

                @include breakpoint(large only) {
                    width: rem-calc(36);
                    height: rem-calc(36);

                    &.front {
                        transform: translateZ(rem-calc(18));
                    }

                    &.back {
                        transform: rotateY(180deg) rotateX(0deg) translateZ(rem-calc(18));
                    }

                    &.left {
                        transform: rotateY(-90deg) translateZ(rem-calc(18));
                    }

                    &.right {
                        transform: rotateY(90deg) translateZ(rem-calc(18));
                    }

                    &.top {
                        transform: rotateX(90deg) translateZ(rem-calc(18));
                    }

                    &.bottom {
                        transform: rotateX(-90deg) rotateZ(-180deg) translateZ(rem-calc(18));
                    }
                }
            }
        }
    }

    &.ctr {
        width: rem-calc(30);
        height: rem-calc(30);
        margin-left: rem-calc(0);
        margin-right: rem-calc(-10);
        margin-top: rem-calc(20);

        @include breakpoint(xlarge) {
            width: rem-calc(70);
            height: rem-calc(70);
            margin-left: rem-calc(10);
            margin-top: rem-calc(30);
        }

        @include breakpoint(large only) {
            width: rem-calc(60);
            height: rem-calc(60);
            margin-left: rem-calc(0);
            margin-top: rem-calc(30);
        }

        .cube {
            animation: spin 16s infinite linear;

            figure {
                width: rem-calc(30);
                height: rem-calc(30);

                &.front {
                    transform: translateZ(rem-calc(15));
                }

                &.back {
                    transform: rotateY(180deg) rotateX(0deg) translateZ(rem-calc(15));
                }

                &.left {
                    transform: rotateY(-90deg) translateZ(rem-calc(15));
                }

                &.right {
                    transform: rotateY(90deg) translateZ(rem-calc(15));
                }

                &.top {
                    transform: rotateX(90deg) translateZ(rem-calc(15));
                }

                &.bottom {
                    transform: rotateX(-90deg) rotateZ(-180deg) translateZ(rem-calc(15));
                    opacity: .55;
                }

                @include breakpoint(xlarge) {
                    width: rem-calc(70);
                    height: rem-calc(70);

                    &.front {
                        transform: translateZ(rem-calc(35));
                    }

                    &.back {
                        transform: rotateY(180deg) rotateX(0deg) translateZ(rem-calc(35));
                    }

                    &.left {
                        transform: rotateY(-90deg) translateZ(rem-calc(35));
                    }

                    &.right {
                        transform: rotateY(90deg) translateZ(rem-calc(35));
                    }

                    &.top {
                        transform: rotateX(90deg) translateZ(rem-calc(35));
                    }

                    &.bottom {
                        transform: rotateX(-90deg) rotateZ(-180deg) translateZ(rem-calc(35));
                    }
                }

                @include breakpoint(large only) {
                    width: rem-calc(50);
                    height: rem-calc(50);

                    &.front {
                        transform: translateZ(rem-calc(25));
                    }

                    &.back {
                        transform: rotateY(180deg) rotateX(0deg) translateZ(rem-calc(25));
                    }

                    &.left {
                        transform: rotateY(-90deg) translateZ(rem-calc(25));
                    }

                    &.right {
                        transform: rotateY(90deg) translateZ(rem-calc(25));
                    }

                    &.top {
                        transform: rotateX(90deg) translateZ(rem-calc(25));
                    }

                    &.bottom {
                        transform: rotateX(-90deg) rotateZ(-180deg) translateZ(rem-calc(25));
                    }
                }
            }
        }
    }

    &.bncube {
        width: rem-calc(50);
        height: rem-calc(50);
        position: absolute;
        left: rem-calc(10);
        top: rem-calc(-20);

        @include breakpoint(xlarge) {
            width: rem-calc(110);
            height: rem-calc(110);
            top: rem-calc(-70);
            left: 0;
        }

        @include breakpoint(large only) {
            width: rem-calc(80);
            height: rem-calc(80);
            top: rem-calc(-50);
            left: 0;
        }

        .cube {
            &.move1{
                animation: spin 30s infinite linear;
            }
            &.move2{
                animation: spin 25s 3s infinite linear;
            }
            &.move3{
                animation: spin 40s infinite linear;
            }
            &.move4{
                animation: spin2 30s infinite linear;
            }
            &.move5{
                animation: spin2 25s 3s infinite linear;
            }
            &.move6{
                animation: spin2 40s infinite linear;
            }

            figure {
                width: rem-calc(50);
                height: rem-calc(50);

                &.front {
                    transform: translateZ(rem-calc(25));
                }

                &.back {
                    transform: rotateY(180deg) rotateX(0deg) translateZ(rem-calc(25));
                }

                &.left {
                    transform: rotateY(-90deg) translateZ(rem-calc(25));
                }

                &.right {
                    transform: rotateY(90deg) translateZ(rem-calc(25));
                }

                &.top {
                    transform: rotateX(90deg) translateZ(rem-calc(25));
                }

                &.bottom {
                    transform: rotateX(-90deg) rotateZ(-180deg) translateZ(rem-calc(25));
                }

                @include breakpoint(xlarge) {
                    width: rem-calc(110);
                    height: rem-calc(110);

                    &.front {
                        transform: translateZ(rem-calc(55));
                    }

                    &.back {
                        transform: rotateY(180deg) rotateX(0deg) translateZ(rem-calc(55));
                    }

                    &.left {
                        transform: rotateY(-90deg) translateZ(rem-calc(55));
                    }

                    &.right {
                        transform: rotateY(90deg) translateZ(rem-calc(55));
                    }

                    &.top {
                        transform: rotateX(90deg) translateZ(rem-calc(55));
                    }

                    &.bottom {
                        transform: rotateX(-90deg) rotateZ(-180deg) translateZ(rem-calc(55));
                    }
                }

                @include breakpoint(large only) {
                    width: rem-calc(80);
                    height: rem-calc(80);

                    &.front {
                        transform: translateZ(rem-calc(40));
                    }

                    &.back {
                        transform: rotateY(180deg) rotateX(0deg) translateZ(rem-calc(40));
                    }

                    &.left {
                        transform: rotateY(-90deg) translateZ(rem-calc(40));
                    }

                    &.right {
                        transform: rotateY(90deg) translateZ(rem-calc(40));
                    }

                    &.top {
                        transform: rotateX(90deg) translateZ(rem-calc(40));
                    }

                    &.bottom {
                        transform: rotateX(-90deg) rotateZ(-180deg) translateZ(rem-calc(40));
                    }
                }
            }
        }
    }

    .cube {
        height: 100%;
        position: absolute;
        transition: .5s all ease-in-out;
        // preserve 3fd effect of child elements
        // default value is 'flat'
        // preserve effects of child elements
        transform-style: preserve-3d;
        // panels global styles
        transform: rotateX(-20deg) rotateY(28deg);
        // tells browser not to render the back of the element
        // Accepts visible | hidden
        backface-visibility: visible;
        width: 100%;

        figure {
            box-sizing: border-box;
            margin: 0;
            display: block;
            position: absolute;
            font-size: 2em;
            line-height: 6em;
            text-align: center;
            // background: linear-gradient(12.9deg, rgba(239, 239, 239, 0.7) 42.27%, rgba(227, 245, 255, 0.3) 71.24%);
            // box-shadow: inset rem-calc(0 16 16) rgba(255, 255, 255, 0.7);
            // backdrop-filter: blur(rem-calc(5));
            border: rem-calc(1) solid rgba(255, 255, 255, 1);
            background-size: cover;
            background-repeat: no-repeat;
            &.front{background-image: url('../images/index/cube-bg1.jpg');}
            &.back{background-image: url('../images/index/cube-bg2.jpg');}
            &.right{background-image: url('../images/index/cube-bg3.jpg');}
            &.left{background-image: url('../images/index/cube-bg4.jpg');}
            &.top{background-image: url('../images/index/cube-bg5.jpg');}
            &.bottom{background-image: url('../images/index/cube-bg6.jpg');}
        }
    }
}

@keyframes spin {
    from {
        transform: rotateX(0) rotateY(0);
    }

    to {
        transform: rotateX(360deg) rotateY(360deg);
    }
}

@keyframes spin2 {
    from {
        transform: rotateX(0) rotateY(0);
    }

    to {
        transform: rotateX(-360deg) rotateY(-360deg);
    }
}
.cubebg-box{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: rem-calc(0);
    left: rem-calc(0);
    z-index: 99;
    pointer-events: none;
    transition: 2s;
    .bncube{
        &.left1{
            left: 5vw;
            top: 20vh;
            transform: scale(0.9) rotate(50deg);
        }
        &.left2{
            left: 10vw;
            top: 70vh;
            transform: scale(1.6) rotate(80deg);
        }
        &.right1{
            left: 72vw;
            top: -3vh;
            transform: scale(0.9) rotate(100deg);
        }
        &.right2{
            left: 88vw;
            top: 30vh;
            transform: scale(0.6) rotate(-30deg);
        }
        &.right3{
            left: 80vw;
            top: 88vh;
            transform: scale(0.9) rotate(-80deg);
        }
    }
    &.is-cubecont{
        left: rem-calc(-500);
        transform: scale(0) translate(rem-calc(1000),rem-calc(0));
        opacity: 0;
        transition: 1s;
    }
}
// --------------------------------------------------------
// /* Cube */ --------------------------------[End]
// --------------------------------------------------------
// --------------------------------------------------------

/* Container */
.pagetext{
    width: 100%;
    max-width: 82vw;
    position: absolute;
    z-index: 2;
    pointer-events: none;
    &.kv{
        right: rem-calc(-100);
        bottom: rem-calc(-20);
        transform: rotate(6deg);
        @media screen and (min-width: 1440px) and (max-width: 1660px){
            right: rem-calc(-100);
            bottom: rem-calc(0);
        }
        @include breakpoint (xlarge only)  {
            right: rem-calc(-50);
            bottom: rem-calc(25);
        }
        @include breakpoint(large only){
            right: rem-calc(-40);
            bottom: rem-calc(35);
        }
        @include breakpoint(medium only){
            right: rem-calc(-60);
            bottom: rem-calc(45);
        }
        @include breakpoint(small down){
            right: rem-calc(-80);
            bottom: rem-calc(75);
        }
    }
    &.news{
        right: rem-calc(320);
        top: rem-calc(-90);
        @include breakpoint (xlarge only)  {
            top: rem-calc(-70);
        }
        @include breakpoint(large only){
            right: rem-calc(290);
            top: rem-calc(-60);
        }
        @include breakpoint(medium only){
            right: rem-calc(290);
            top: rem-calc(-50);
        }
        @include breakpoint(small down){
            right: rem-calc(250);
            top: rem-calc(-20);
        }
    }
    img{
        width: 100%;
    }
}
// --------------------------------------------------------
// /* KV */ --------------------------------[Start]
// --------------------------------------------------------
.in-kv{
    width:100%;
    min-height: rem-calc(1000);
    z-index: 19;
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(110);
    @include breakpoint (xlarge only)  {
        min-height: rem-calc(700);
    }
    @include breakpoint(large only){
        min-height: rem-calc(600);
    }
    @include breakpoint(medium only){
        min-height: rem-calc(480);
    }
    @include breakpoint(small down){
        min-height: rem-calc(560);
        padding-top: rem-calc(50);
        padding-bottom: rem-calc(150);
    }
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.95);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    .cubebg-box{
        z-index: 3;
    }
    .ct_ctr{
        max-width: rem-calc(1300);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        z-index: 9;
        @media screen and (min-width: 1440px) and (max-width: 1500px){
            max-width: rem-calc(1100);
        }
        @include breakpoint (xlarge only)  {
            max-width: rem-calc(980);
        }
        @include breakpoint(large only){
            max-width: rem-calc(800);
        }
        @include breakpoint(medium only){
            max-width: rem-calc(550);
        }
        @include breakpoint(small down){
            max-width: rem-calc(335);
        }
        .kv-pic{
            width: 100%;
            max-height: rem-calc(1000);
            position: absolute;
            left: 0;
            top: 0;
            @include breakpoint(small down){
                width: 130%;
                left: -15%;
            }
            img{
                width: 100%;
            }
            &.logo{
                max-width: rem-calc(500);
                z-index: 10;
                top: inherit;
                bottom: rem-calc(100);
                left: calc(50% - 250px);
                @include breakpoint (xlarge only)  {
                    max-width: rem-calc(400);
                    bottom: rem-calc(90);
                    left: calc(50% - 200px);
                }
                @include breakpoint (large only)  {
                    max-width: rem-calc(320);
                    bottom: rem-calc(80);
                    left: calc(50% - 160px);
                }
                @include breakpoint(medium only){
                    max-width: rem-calc(210);
                    bottom: rem-calc(50);
                    left: calc(50% - 105px);
                }
                @include breakpoint(small down){
                    max-width: rem-calc(210);
                    bottom: rem-calc(-60);
                    left: calc(50% - 105px);
                }
            }
            &.bg1{
                position: relative;
            }
            &.bg3,&.per3{
                z-index: 9;
            }
            &.bg2,&.per2,&.bg4,&.per4{
                z-index: 8;
            }
            &.bg1,&.per1,&.bg5,&.per5{
                z-index: 7;
            }
            @include breakpoint(small down){
                &.bg1,&.per1{
                    transform: translate(rem-calc(35),rem-calc(80));
                }
                &.bg2,&.per2{
                    transform: translate(rem-calc(15),rem-calc(40));
                }
                &.bg3,&.per3{
                    transform: translate(rem-calc(0),rem-calc(-20));
                }
                &.bg4,&.per4{
                    transform: translate(rem-calc(-8),rem-calc(40));
                }
                &.bg5,&.per5{
                    transform: translate(rem-calc(-20),rem-calc(80));
                }
            }
        }
    }
}
.in-kv{
    &:before{
        opacity: 0;
        transition: 0.4s;
    }
    .cubebg-box{
        opacity: 0;
        transform: scale(0.6);
        transform: 0.4s;
    }
    .kv-pic{
        opacity: 0;
        transition: 0.4s;
        &.logo{transform: scale(1.3);}
        &.bg1{transform: translate(rem-calc(20),rem-calc(30));}
        &.bg2{transform: translate(rem-calc(20),rem-calc(30))}
        &.bg3{transform: translate(rem-calc(0),rem-calc(30))}
        &.bg4{transform: translate(rem-calc(-20),rem-calc(30))}
        &.bg5{transform: translate(rem-calc(-20),rem-calc(30))}
        &.per1{transform: translate(rem-calc(-20),rem-calc(-30))}
        &.per2{transform: translate(rem-calc(-20),rem-calc(-30))}
        &.per3{transform: translate(rem-calc(0),rem-calc(-30))}
        &.per4{transform: translate(rem-calc(20),rem-calc(-30))}
        &.per5{transform: translate(rem-calc(20),rem-calc(-30))}
    }
    &.open{
        &:before{
            opacity: 1;
            transition: 0.4s 0.4s;
        }
    }
    &.hover{
        .cubebg-box{
            opacity: 1;
            transform: scale(1);
            transition: 0.6s 2.8s;
        }
        .kv-pic{
            opacity: 1;
            transform: translate(0) scale(1);
            &.logo{transition: 0.8s 2.6s;}
            &.bg1{transition: 0.4s 1.2s;}
            &.bg2{transition: 0.4s 1.4s;}
            &.bg3{transition: 0.4s 1.6s;}
            &.bg4{transition: 0.4s 1.8s;}
            &.bg5{transition: 0.4s 2.2s;}
            &.per1{transition: 0.4s 1.4s;}
            &.per2{transition: 0.4s 1.6s;}
            &.per3{transition: 0.4s 1.8s;}
            &.per4{transition: 0.4s 2s;}
            &.per5{transition: 0.4s 2.2s;}
        }
    }
}
// --------------------------------------------------------
// /* KV */ --------------------------------[End]
// --------------------------------------------------------
// --------------------------------------------------------
// /* news */ --------------------------------[Start]
// --------------------------------------------------------
@keyframes bgchange {
    0%{opacity: 0;}
    4%{opacity: 1;}
    30%{opacity: 1;}
    34%{opacity: 0;}
    100%{opacity: 0;}
}
.in-news{
    width: calc(100vw + 200px);
    min-height: rem-calc(600);
    z-index: 20;
    margin-left: rem-calc(-100);
    margin-top: rem-calc(-100);
    overflow: hidden;
    transform: rotate(6deg) translateZ(0);
    // -webkit-transform: rotate(6deg) translateZ(0);
    // backface-visibility: hidden;
    // transform-style: preserve-3d;
    &_in{
        width: 100%;
        margin-top: rem-calc(-110);
        margin-bottom: rem-calc(-110);
        background-image: url('../images/bg-pic.jpg');
        background-repeat: repeat;
        background-size: 200px 200px;
        transform: rotate(-6deg) translateZ(0);
        // -webkit-transform: rotate(-6deg) translateZ(0);
        // backface-visibility: hidden;
        // transform-style: preserve-3d;
        &_bg{
            width: rem-calc(900);
            height: rem-calc(1100);
            position: absolute;
            right: 0;
            top: rem-calc(100);
            background-size: cover;
            z-index: 3;
            @include breakpoint (xlarge only , large only)  {
                width: rem-calc(550);
                height: rem-calc(800);
                top: rem-calc(30);
            }
            @include breakpoint (medium only)  {
                width: rem-calc(550);
                height: rem-calc(1100);
                top: rem-calc(30);
            }
            @include breakpoint(small down){
                width: rem-calc(300);
                height: rem-calc(520);
                top: rem-calc(30);
                right: rem-calc(100);
            }
            &.bg1{
                background-image: url('../images/index/news/bgbox-r.svg');
                animation: bgchange 12s 3s linear infinite;
            }
            &.bg2{
                background-image: url('../images/index/news/bgbox-r2.svg');
                animation: bgchange 12s 7s linear infinite;
            }
            &.bg3{
                background-image: url('../images/index/news/bgbox-r3.svg');
                animation: bgchange 12s 11s linear infinite;
            }
        }
        .news-ctr{
            padding: rem-calc(260 0 260 0);
            @include breakpoint (xlarge only , large only)  {
                padding: rem-calc(200 0 260 0);
            }
            @include breakpoint(medium only){
                max-width: rem-calc(580);
                padding: rem-calc(200 0 200 0);
            }
            @include breakpoint(small down){
                max-width: rem-calc(340);
                padding: rem-calc(200 0 200 0);
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }
            .grid-x{
                @include breakpoint(small down){
                    width: 100%;
                }
            }
            .in-title{
                margin: 0 auto;
            }
        }
    }
    .news-ctr{
        &_left{
            // margin: rem-calc(-50 80 0 80);
            margin-top: rem-calc(-50);
            padding: rem-calc(0 80);
            position: relative;
            z-index: 9;
            @include breakpoint(large only){
                padding: rem-calc(0 40);
            }
            @include breakpoint(medium only){
                padding: rem-calc(0 60);
            }
            @include breakpoint(small down){
                margin-top: rem-calc(20);
                padding: rem-calc(0 0);
            }
            .swiper-arrow{
                top: calc(50% - 17px);
                @include breakpoint(large){
                    top: calc(50% + 10px);
                }
                &.prev{
                    left: rem-calc(-30);
                    @include breakpoint (xlarge down)  {
                        left: rem-calc(-20);
                    }
                }
                &.next{
                    right: rem-calc(-30);
                    transform: rotate(180deg);
                    @include breakpoint (xlarge down)  {
                        right: rem-calc(-20);
                    }
                }
            }
        }
        &_right{
            padding: rem-calc(55 120 0 0);
            position: relative;
            z-index: 19;
            @include breakpoint (xlarge only)  {
                padding: rem-calc(25 100 0 0);
            }
            @include breakpoint(large only){
                padding: rem-calc(15 80 0 0);
            }
            @include breakpoint(medium only){
                padding: rem-calc(0 60 0 60);
                margin-top: rem-calc(60);
            }
            @include breakpoint(small down){
                padding: rem-calc(0 0 0 0);
                margin-top: rem-calc(60);
            }
            .rdzvbtn{
                margin-top: rem-calc(30);
            }
        }
    }
    .newscard{
        .ncbox{
            overflow: hidden;
            &-btmpic{
                height: 54%;
                margin-top: rem-calc(122);
                margin-bottom: rem-calc(-122);
                overflow: hidden;
                position: relative;
                z-index: 1;
                opacity: 0.4;
                -webkit-filter: grayscale(100%); /* Safari */
                filter: grayscale(100%);
                @include breakpoint (xlarge only)  {
                    height: 50%;
                    margin-top: rem-calc(95);
                    margin-bottom: rem-calc(-95);
                }
                @include breakpoint (large only)  {
                    height: 45%;
                    margin-top: rem-calc(98);
                    margin-bottom: rem-calc(-98);
                }
                @include breakpoint (medium only)  {
                    height: 45%;
                    margin-top: rem-calc(98);
                    margin-bottom: rem-calc(-98);
                    opacity: 1;
                    -webkit-filter: grayscale(0%); /* Safari */
                    filter: grayscale(0%);
                }
                @include breakpoint (small only)  {
                    height: 50%;
                    margin-top: rem-calc(48);
                    margin-bottom: rem-calc(-48);
                    opacity: 1;
                    -webkit-filter: grayscale(0%); /* Safari */
                    filter: grayscale(0%);
                }
            }
        }
        .swiper-pagination{
            margin-top: rem-calc(30);
            bottom: inherit;
            @include breakpoint (medium only)  {
                margin-top: rem-calc(30);
            }
        }
    }
}
.in-news{
    .news-ctr{
        .in-title{
            opacity: 0;
            transform: translateX(rem-calc(-50));
            transition: 0.4s;
        }
        &_left{
            opacity: 0;
            transform: translateX(rem-calc(-50));
            transition: 0.4s;
        }
        &_right{
            opacity: 0;
            transform: translateX(rem-calc(50));
            transition: 0.4s;
        }
    }
    &.hover{
        .news-ctr{
            .in-title{
                opacity: 1;
                transform: translateX(rem-calc(0));
                transition: 0.6s 0.8s;
            }
            &_left,&_right{
                opacity: 1;
                transform: translateX(rem-calc(0));
                transition: 0.6s 0.4s;
            }
        }
    }
}
// --------------------------------------------------------
// /* news */ --------------------------------[End]
// --------------------------------------------------------
// --------------------------------------------------------
// /* CUBE 方塊世界介紹 */ --------------------------------[Start]
// --------------------------------------------------------
@keyframes bglight {
    0%{opacity: 0;}
    25%{opacity: 1;}
    50%{opacity: 0;}
    75%{opacity: 1;}
    100%{opacity: 0;}
}
.in-cu{
    width: calc(100vw + 200px);
    padding: rem-calc(360 0 380 0);
    margin-top: rem-calc(-110);
    margin-left: rem-calc(-100);
    z-index: 19;
    background: linear-gradient(to bottom,rgba($black,1),rgba($black2,1),rgba($black3,1),rgba($white,1));
    // transform: rotate(6deg);
    @include breakpoint (xlarge only,large only)  {
        padding: rem-calc(260 0 220 0);
    }
    @include breakpoint(medium down){
        padding: rem-calc(160 0 0 0);
        margin-bottom: rem-calc(-25);
    }
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('../images/index/cu/light.png');
        background-size: cover;
        transform: scale(1.2);
        animation: bglight 8s linear infinite;
    }
    &_in{
        // transform: rotate(-6deg);
        .cu-ctr{
            padding: rem-calc(0 80);
            justify-content: space-between;
            @include breakpoint (large only)  {
                padding: rem-calc(0 40);
            }
            @include breakpoint(medium down){
                flex-wrap: wrap;
            }
        }
        .cu-title{
            width: rem-calc(500);
            position: relative;
            z-index: 9;
            @include breakpoint (xlarge only)  {
                width: rem-calc(400);
            }
            @include breakpoint (large only)  {
                width: rem-calc(350);
            }
            @include breakpoint(medium only){
                width: 100%;
                margin: rem-calc(0 40);
            }
            @include breakpoint(small down){
                width: calc(100% - 40px);
                margin: rem-calc(0 20);
            }
            &-top{
                &_cht{
                    color: rgba($white,1);
                    font-size: rem-calc(34);
                    margin: rem-calc(0 0 -20 0);
                    @include breakpoint (xlarge only , large only)  {
                        font-size: rem-calc(30);
                    }
                    @include breakpoint(small down){
                        font-size: rem-calc(30);
                        margin: rem-calc(0 0 -10 0);
                        text-align: center;
                    }
                }
                &_ent{
                    color: rgba($white,1);
                    text-align: right;
                    font-size: rem-calc(65);
                    @include breakpoint (xlarge only , large only)  {
                        font-size: rem-calc(50);
                    }
                    @include breakpoint(small down){
                        text-align: center;
                        font-size: rem-calc(50);
                    }
                }
            }
            &-btm{
                margin-top: rem-calc(-45);
                padding: rem-calc(50 50);
                background-color: rgba($white,0.85);
                backdrop-filter: blur(3px);
                @include breakpoint (xlarge only , large only)  {
                    margin-top: rem-calc(-40);
                    padding: rem-calc(40);
                }
                @include breakpoint(small down){
                    margin-top: rem-calc(-40);
                    padding: rem-calc(40);
                }
                &_cht{
                    font-size: rem-calc(45);
                    font-weight: 700;
                    background: linear-gradient(to right,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1),rgba($gr-bl,1),rgba($gr-gr,1),rgba($gr-ye,1));
                    background: -webkit-linear-gradient(to right,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1),rgba($gr-bl,1),rgba($gr-gr,1),rgba($gr-ye,1));
                    background-clip: text;
                    -webkit-background-clip: text;
                    color: transparent;
                    display: table;
                    margin: 0 auto;
                    letter-spacing: rem-calc(2);
                    @include breakpoint (xlarge down)  {
                        font-size: rem-calc(38);
                    }
                }
                &_cha{
                    font-size: rem-calc(14);
                    line-height: 160%;
                    margin: rem-calc(25 0 20 0);
                    @include breakpoint (xlarge only , large only)  {
                        margin: rem-calc(10 0 10 0);
                    }
                }
            }
        }
        .cu-boxcont {
            width: calc(100% - 500px);
            max-width: rem-calc(500);
            min-height: rem-calc(500);
            // background-color: rgba($black,0.6);
            position: relative;
            z-index: 1;
            @include breakpoint (large)  {
                .bncube{
                    position: absolute;
                    transition: 2s;
                    &.cube1{
                        top: inherit;
                        bottom: rem-calc(-80);
                        left: rem-calc(-250);
                        transform: scale(2);
                    }
                    &.cube2{
                        top: inherit;
                        bottom: rem-calc(-100);
                        left: rem-calc(-600);
                        transform: rotate(-150deg) scale(1.7);
                    }
                    &.cube3{
                        top: inherit;
                        bottom: rem-calc(0);
                        left: rem-calc(-880);
                        transform: scale(1.3);
                        z-index: 2;
                    }
                    &.cube4{
                        top: inherit;
                        bottom: rem-calc(120);
                        left: rem-calc(-1000);
                        transform: scale(1.3);
                        z-index: 1;
                    }
                    &.cube5{
                        top: inherit;
                        bottom: rem-calc(330);
                        left: rem-calc(-1100);
                        transform: scale(1.2);
                    }
                    &.cube6{
                        top: inherit;
                        bottom: rem-calc(520);
                        left: rem-calc(-1000);
                        transform: scale(1);
                    }
                    &.cube7{
                        top: inherit;
                        bottom: rem-calc(580);
                        left: rem-calc(-800);
                        transform: scale(0.85);
                    }
                    &.cube8{
                        top: inherit;
                        bottom: rem-calc(550);
                        left: rem-calc(-610);
                        transform: scale(0.75);
                    }
                    &.cube9{
                        top: inherit;
                        bottom: rem-calc(470);
                        left: rem-calc(-490);
                        transform: scale(0.6) rotate(60deg);
                    }
                    &.cube10{
                        top: inherit;
                        bottom: rem-calc(385);
                        left: rem-calc(-410);
                        transform: scale(0.5);
                    }
                    &.cube11{
                        top: inherit;
                        bottom: rem-calc(280);
                        left: rem-calc(-410);
                        transform: scale(0.45);
                    }
                    &.cube12{
                        top: inherit;
                        bottom: rem-calc(200);
                        left: rem-calc(-490);
                        transform: scale(0.4);
                    }
                    &.cube13{
                        top: inherit;
                        bottom: rem-calc(180);
                        left: rem-calc(-590);
                        transform: scale(0.4);
                    }
                    &.cube14{
                        top: inherit;
                        bottom: rem-calc(210);
                        left: rem-calc(-690);
                        transform: scale(0.35);
                    }
                    &.cube15{
                        top: inherit;
                        bottom: rem-calc(250);
                        left: rem-calc(-710);
                        transform: scale(0.3);
                    }
                    &.cube16{
                        top: inherit;
                        bottom: rem-calc(280);
                        left: rem-calc(-680);
                        transform: scale(0.2);
                    }
                }
            }
            @include breakpoint (xlarge only)  {
                width: calc(100% - 400px);
                max-width: rem-calc(400);
                transform: scale(0.7);
            }
            @include breakpoint (large only)  {
                width: calc(100% - 350px);
                max-width: rem-calc(350);
                min-height: rem-calc(350);
                transform: scale(0.7) rotate(-7deg);
            }
            @include breakpoint(medium down){
                width: 100%;
                max-width: rem-calc(1000);
                transform: scale(1.2) rotate(0deg) ;
                margin: rem-calc(-180 0 0 0);
            }
        }
        .cu-boxcontpad{
            width: calc(100% - 315px);
            position: absolute;
            right: rem-calc(-77);
            top: rem-calc(-111);
            &.is-padpro{
                display: block;
            }
            &.no-padpro{
                display: none;
            }
        }
    }
}
@include breakpoint (large)  {
    .in-cu{
        .cu-title{
            &-top,&-btm{
                opacity: 0;
                transform: translateX(rem-calc(-100));
                transition: 0.4s;
            }
        }
        .cu-boxcont{
            .bncube{
                opacity: 0;
                transition: 0.4s;
            }
        }
        &.hover{
            .cu-title{
                &-top{
                    opacity: 1;
                    transform: translateX(rem-calc(0));
                    transition: 0.8s 1.6s;
                }
                &-btm{
                    opacity: 1;
                    transform: translateX(rem-calc(0));
                    transition: 0.8s 1.8s;
                }
            }
            .bncube{
                opacity: 1;
                &.cube1{transition: 0.4s 1.5s;}
                &.cube2{transition: 0.4s 1.4s;}
                &.cube3{transition: 0.4s 1.3s;}
                &.cube4{transition: 0.4s 1.2s;}
                &.cube5{transition: 0.4s 1.1s;}
                &.cube6{transition: 0.4s 1s;}
                &.cube7{transition: 0.4s 0.9s;}
                &.cube8{transition: 0.4s 0.8s;}
                &.cube9{transition: 0.4s 0.7s;}
                &.cube10{transition: 0.4s 0.6s;}
                &.cube11{transition: 0.4s 0.5s;}
                &.cube12{transition: 0.4s 0.4s;}
                &.cube13{transition: 0.4s 0.3s;}
                &.cube14{transition: 0.4s 0.2s;}
                &.cube15{transition: 0.4s 0.1s;}
                &.cube16{transition: 0.4s 0s;}
            }
        }
    }
}
// --------------------------------------------------------
// /* CUBE 方塊世界介紹 */ --------------------------------[End]
// --------------------------------------------------------
// --------------------------------------------------------
// /* protagonist 主角介紹 */ --------------------------------[Start]
// --------------------------------------------------------
.in-pro{
    background-image: url('../images/index/cu/contbg.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: rem-calc(350);
    z-index: 20;
    @include breakpoint (xlarge only)  {
        padding-bottom: rem-calc(340);
    }
    @include breakpoint (large only)  {
        padding-bottom: rem-calc(340);
    }
    .perbg{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: rem-calc(0);
        opacity: 0;
        transform: scale(0.95);
        transition: 0.4s;
        @include breakpoint (large only)  {
            bottom: rem-calc(0);
        }
        @include breakpoint (medium down)  {
            bottom: rem-calc(160);
        }
        &.is-active{
            opacity: 1;
            transform: scale(1.2);
            transition: 1s 0.6s;
        }
    }
    //- 三原神區塊
    .gitd{
        width: 100%;
        justify-content: center;
        position: relative;
        z-index: 20;
        transition: 1s;
        @include breakpoint(small down){
            width: calc(100% + 40px);
            margin-left: rem-calc(-20);
        }
        &-box{
            width: rem-calc(385);
            height: rem-calc(385);
            // background-color: rgba($black,0.3);
            margin: rem-calc(0 8);
            position: relative;
            transition: 1s;
            @include breakpoint (xlarge only)  {
                width: rem-calc(320);
                height: rem-calc(320);
            }
            @include breakpoint (large only)  {
                width: rem-calc(280);
                height: rem-calc(280);
            }
            @include breakpoint(medium down){
                width: calc(100% / 3);
                height: auto;
                margin: rem-calc(0);
            }
            &.mon{
                .gitd-box_name{
                    left: rem-calc(20);
                    top: rem-calc(20);
                    @include breakpoint(small down){
                        left: rem-calc(10);
                        top: rem-calc(10);
                    }
                }
                .gitd-box_pic{
                    left: 0;
                    bottom: 0;
                    @include breakpoint(large){
                        transform: translateX(rem-calc(-90));
                    }
                    @include breakpoint(small down){
                        transform: translateY(rem-calc(10));
                    }
                }
                .gitd-box_bg{
                    transition: 1s;
                }
            }
            &.sea{
                .gitd-box_name{
                    right: rem-calc(20);
                    bottom: rem-calc(20);
                    @include breakpoint (medium only)  {
                        bottom: inherit;
                        top: rem-calc(20);
                    }
                    @include breakpoint(small down){
                        bottom: inherit;
                        right: rem-calc(10);
                        top: rem-calc(10);
                    }
                }
                .gitd-box_pic{
                    left: 0;
                    bottom: rem-calc(0);
                    transform: translate(rem-calc(0),rem-calc(70));
                    @include breakpoint(large){
                        transform: translate(rem-calc(0),rem-calc(-60));
                    }
                    @include breakpoint(small down){
                        transform: translateY(rem-calc(35));
                    }
                }
                .gitd-box_bg{
                    transition: 2s;
                }
            }
            &.deer{
                .gitd-box_name{
                    right: rem-calc(20);
                    top: rem-calc(20);
                    @include breakpoint(small down){
                        right: rem-calc(10);
                        top: rem-calc(10);
                    }
                }
                .gitd-box_pic{
                    left: 0;
                    bottom: rem-calc(0);
                    transform: translate(rem-calc(0),rem-calc(80));
                    @include breakpoint(small down){
                        transform: translateY(rem-calc(45));
                    }
                }
                .gitd-box_bg{
                    transition: 4s;
                }
            }
            &_pic{
                position: absolute;
                z-index: 3;
                transition: 0.6s;
                img{
                    width: 100%;
                }
            }
            &_name{
                display: block;
                position: absolute;
                z-index: 2;
                padding: rem-calc(16 4 3 4);
                background: linear-gradient(to right,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1));
                @include breakpoint (medium only)  {
                    padding: rem-calc(13 4 0 4);
                }
                @include breakpoint(small down){
                    padding: rem-calc(8 3 0 2);
                    width: rem-calc(20.8);
                }
                &::before,&::after{
                    content: '';
                    position: absolute;
                    transition: 0.6s;
                }
                &::before{
                    width: 100%;
                    height: 100%;
                    background-color: rgba($black,1);
                    left: 0;
                    top: 0;
                    z-index: 1;
                }
                &::after{
                    background-color: rgba($white,1);
                    width: calc(100% - 10px);
                    height: calc(100% - 10px);
                    left: rem-calc(5);
                    top: rem-calc(5);
                    z-index: 2;
                    @include breakpoint(small down){
                        width: calc(100% - 4px);
                        height: calc(100% - 4px);
                        left: rem-calc(2);
                        top: rem-calc(2);
                    }
                }
                h4{
                    display: block;
                    writing-mode: vertical-rl;
                    font-size: rem-calc(32);
                    letter-spacing: rem-calc(10);
                    font-weight: 700;
                    position: relative;
                    z-index: 3;
                    @include breakpoint (xlarge only,large only)  {
                        font-size: rem-calc(26);
                    }
                    @include breakpoint (medium only)  {
                        font-size: rem-calc(22);
                        letter-spacing: rem-calc(6);
                    }
                    @include breakpoint(small down){
                        font-size: rem-calc(12);
                        letter-spacing: rem-calc(2);
                    }
                }
            }
            &_bg{
                position: relative;
                z-index: 1;
                -webkit-filter: grayscale(100%); /* Safari */
                filter: grayscale(100%);
            }
            @include breakpoint (large) {
                &:hover{
                    .gitd-box_pic{
                        transform: translate(rem-calc(0),rem-calc(0));
                        transition: 0.6s;
                    }
                    .gitd-box_name{
                        &::before{
                            opacity: 0;
                            transition: 0.6s;
                        }
                        &::after{
                            width: calc(100% - 10px);
                            height: calc(100% - 10px);
                            left: rem-calc(5);
                            top: rem-calc(5);
                            transition: 0.6s;
                        }
                    }
                    .gitd-box_bg{
                        -webkit-filter: grayscale(0%); /* Safari */
                        filter: grayscale(0%);
                        transition: 0.6s;
                    }
                }
            }
        }
        &.is-topcube{
            transform: translate(rem-calc(-120),rem-calc(-280)) scale(1.05) rotate(20deg);
            transition: 2s;
            @include breakpoint (xlarge only)  {
                transform: translate(rem-calc(-180),rem-calc(-120)) scale(1.05) rotate(20deg);
            }
            @include breakpoint (large only)  {
                transform: translate(rem-calc(-180),rem-calc(0)) scale(1) rotate(20deg);
            }
            .gitd-box{
                transform: translateY(rem-calc(-680));
                &.mon{
                    transition: 2s 0.4s;
                    .gitd-box_bg{
                        transform-style: preserve-3d;
                        // panels global styles
                        transform: rotateX(29deg) rotateY(45deg) translateX(rem-calc(749)) translateY(rem-calc(-293));
                        // tells browser not to render the back of the element
                        // Accepts visible | hidden
                        backface-visibility: visible;;
                        &::before{
                            background: radial-gradient(150.08% 67.74% at 46.05% 49.96%, rgba(251, 254, 255, 0.00) 0%, #C6C6C6 100%)
                        }
                        @include breakpoint (xlarge only)  {
                            transform: rotateX(29deg) rotateY(45deg) translateX(rem-calc(595)) translateY(rem-calc(-200));
                        }
                        @include breakpoint (large only)  {
                            transform: rotateX(29deg) rotateY(45deg) translateX(rem-calc(555)) translateY(rem-calc(-200));
                        }
                        
                    }
                }
                &.sea{
                    transition: 1.8s;
                    .gitd-box_bg{
                        transform-style: preserve-3d;
                        // panels global styles
                        transform: rotateX(61deg) rotateY(0deg) rotateZ(45deg) translateX(rem-calc(-154)) translateY(rem-calc(-529));
                        // tells browser not to render the back of the element
                        // Accepts visible | hidden
                        backface-visibility: visible;
                        &::before{
                            background: radial-gradient(55.97% 54.03% at 50.03% 49.78%, rgba(255, 255, 255, 0.00) 0%, #FDFDFD 100%);
                        }
                        @include breakpoint (xlarge only)  {
                            transform: rotateX(61deg) rotateY(0deg) rotateZ(45deg) translateX(rem-calc(-102)) translateY(rem-calc(-382));
                        }
                        @include breakpoint (large only)  {
                            transform: rotateX(61deg) rotateY(0deg) rotateZ(45deg) translateX(rem-calc(-90)) translateY(rem-calc(-366));
                        }
                    }
                }
                &.deer{
                    transition: 2s 0.6s;
                    .gitd-box_bg{
                        transform-style: preserve-3d;
                        // panels global styles
                        transform: rotateX(-29deg) rotateY(45deg);
                        // tells browser not to render the back of the element
                        // Accepts visible | hidden
                        backface-visibility: visible;
                        &::before{
                            background: radial-gradient(63.78% 78.69% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, #B8B8B8 100%);
                        }
                        @include breakpoint (xlarge only)  {
                            transform: rotateX(-29deg) rotateY(45deg) translateX(rem-calc(-36)) translateY(rem-calc(20));
                        }
                        @include breakpoint (large only)  {
                            transform: rotateX(-29deg) rotateY(45deg) translateX(rem-calc(-2)) translateY(rem-calc(16));
                        }
                    }
                }
                &_name{
                    opacity: 0;
                }
                &_pic{
                    opacity: 0;
                }
                &_bg{
                    -webkit-filter: grayscale(0%); /* Safari */
                    filter: grayscale(0%);
                    transition: 2s;
                    &::before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
    }
    //- 上下連貫區
    .union{
        width: 100%;
        margin: rem-calc(70 0);
        position: relative;
        justify-content: space-between;
        z-index: 21;
        @include breakpoint (large only,medium only) {
            margin: rem-calc(40 0);
        }
        @include breakpoint (small down) {
            margin: rem-calc(30 0);
            z-index: 19;
        }
        &-box{
            width: 100%;
            @include breakpoint(medium){
                width: calc(100% / 3);
            }
            h2,h3{
                text-align: center;
                color: rgba($white,1);
                background-color: rgba($black,1);
                margin: 0;
                padding: rem-calc(8 30);
                @include breakpoint(small down){
                    padding: rem-calc(8 15);
                }
            }
            h2{
                font-size: rem-calc(42);
                margin-top: rem-calc(15);
                @include breakpoint (xlarge only,large only)  {
                    font-size: rem-calc(36);
                }
                @include breakpoint (medium only)  {
                    font-size: rem-calc(26);
                }
                @include breakpoint(small down){
                    font-size: rem-calc(26);
                }
            }
            h3{
                @include breakpoint (xlarge only,large only)  {
                    font-size: rem-calc(28);
                }
                @include breakpoint (medium only)  {
                    font-size: rem-calc(18);
                }
                @include breakpoint(small down){
                    font-size: rem-calc(18);
                }
            }
            &.left{
                flex-direction:column;
                align-items: center;
                @include breakpoint (xlarge)  {
                    transform: translate(rem-calc(125),rem-calc(-90));
                }
                @include breakpoint (medium)  {
                    align-items: flex-end;
                }
                @include breakpoint (large only)  {
                    transform: translate(rem-calc(50),rem-calc(-60));
                }
                @include breakpoint (medium only)  {
                    transform: translate(rem-calc(30),rem-calc(-60));
                }
                h3{
                    @include breakpoint (medium)  {
                        transform: translateX(rem-calc(-40));
                    }
                }
            }
            &.ctr{
                width: rem-calc(124);
                justify-content: center;
                @include breakpoint (xlarge only,large only)  {
                    width: rem-calc(100);
                }
                @include breakpoint (medium down)  {
                    width: rem-calc(80);
                    margin: rem-calc(0) auto;
                    img{
                        transform: scale(0.6);
                    }
                }
            }
            &.right{
                flex-direction:column;
                align-items: center;
                @include breakpoint (medium)  {
                    transform: translate(rem-calc(-180),rem-calc(90));
                    align-items: flex-start;
                    justify-content: flex-end;
                }
                @include breakpoint (large only)  {
                    transform: translate(rem-calc(-60),rem-calc(60));
                }
                @include breakpoint (medium only)  {
                    transform: translate(rem-calc(-80),rem-calc(60));
                }
                h3{
                    transform: translateX(rem-calc(80));
                    @include breakpoint (large only)  {
                        transform: translateX(rem-calc(60));
                    }
                    @include breakpoint (small down)  {
                        transform: translateX(rem-calc(0));
                    }
                }
            }
        }
    }
    //- 主角孩子們
    .pitd{
        justify-content: center;
        margin-top: rem-calc(120);
        margin-bottom: rem-calc(200);
        // background-color: rgba($black,0.3);
        @include breakpoint (large)  {
            justify-content: flex-end;
            margin-top: rem-calc(200);
        }
        @include breakpoint (large only,medium only)  {
            margin-top: rem-calc(140);
        }
        @include breakpoint (small down)  {
            margin-top: rem-calc(60);
            margin-bottom: rem-calc(100);
        }
        &-box{
            width: rem-calc(160);
            height: rem-calc(420);
            position: relative;
            margin: rem-calc(0 15);
            @include breakpoint (xlarge only)  {
                width: rem-calc(120);
                height: rem-calc(330);
            }
            @include breakpoint (large only)  {
                width: rem-calc(100);
                height: rem-calc(280);
            }
            @include breakpoint (medium only)  {
                width: rem-calc(100);
                height: rem-calc(240);
                margin: rem-calc(0 5);
            }
            @include breakpoint (small down)  {
                width: rem-calc(60);
                height: rem-calc(180);
                margin: rem-calc(0 3);
            }
            &::before{
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                transition: 0.4s 0.2s;
            }
            &:not(.subtitle){
                cursor: pointer;
            }
            &.subtitle{
                width: rem-calc(660);
                position: absolute;
                left: rem-calc(-80);
                bottom: rem-calc(-60);
                z-index: 10;
                @include breakpoint (xlarge only)  {
                    width: rem-calc(430);
                    left: rem-calc(-20);
                    bottom: rem-calc(-20);
                }
                @include breakpoint (large only)  {
                    width: rem-calc(360);
                    left: rem-calc(-20);
                    bottom: rem-calc(0);
                }
                @include breakpoint (medium only)  {
                    width: rem-calc(380);
                    left: rem-calc(20);
                    bottom: rem-calc(-60);
                }
                @include breakpoint (small down)  {
                    width: rem-calc(270);
                    left: rem-calc(20);
                    bottom: rem-calc(-70);
                }
            }
            &.per1,&.per3,&.per4{
                .pitd-box_per{
                    top: 0;
                }
            }
            &.per2,&.per5{
                .pitd-box_per{
                    bottom: 0;
                }
            }
            // 三角形
            &.per1,&.per3{
                &::before{
                    border-width: rem-calc(0 160 60 0);
                    border-color: transparent #ffffff transparent transparent;
                    border-style: solid;
                    @include breakpoint (small down)  {
                        border-width: rem-calc(0 60 20 0);
                    }
                }
                .pitd-box_name{
                    top: rem-calc(-30);
                    @include breakpoint(large){
                        top: rem-calc(50);
                    }
                }
            }
            &.per2,&.per4,&.per5{
                &::before{
                    border-width: rem-calc(60 160 0 0);
                    border-color: #ffffff transparent transparent transparent;
                    border-style: solid;
                    @include breakpoint (small down)  {
                        border-width: rem-calc(20 60 0 0);
                    }
                }
                .pitd-box_name{
                    top: rem-calc(-30);
                    @include breakpoint(large){
                        top: inherit;
                        bottom: rem-calc(50);
                    }
                }
            }
            // 錯位位移
            &.per1{
                .pitd-box_per{img{
                    transform: scale(1.55) translate(rem-calc(0),rem-calc(30));
                    @include breakpoint (small down)  {
                        transform: scale(1.55) translate(rem-calc(-5),rem-calc(10));
                    }
                }}
            }
            &.per2{
                transform: translateY(rem-calc(30));
                @include breakpoint (medium)  {
                    transform: translateY(rem-calc(90));
                }
                .pitd-box_per{img{transform: scale(1.5);}}
            }
            &.per3{
                .pitd-box_per{img{
                    transform: scale(1.45) translate(rem-calc(0),rem-calc(30));
                    @include breakpoint (small down)  {
                        transform: scale(1.45) translate(rem-calc(0),rem-calc(10));
                    }
                }}
            }
            &.per4{
                transform: translateY(rem-calc(30));
                @include breakpoint (medium)  {
                    transform: translateY(rem-calc(90));
                }
                .pitd-box_per{img{
                    transform: scale(1.7) translate(rem-calc(0),rem-calc(50));
                    @include breakpoint (small down)  {
                        transform: scale(1.7) translate(rem-calc(0),rem-calc(20));
                    }
                }}
            }
            &.per5{
                transform: translateY(rem-calc(-30));
                .pitd-box_per{img{
                    transform: scale(1.75) translate(rem-calc(20),rem-calc(0));
                    @include breakpoint (medium only)  {
                        transform: scale(1.75) translate(rem-calc(5),rem-calc(0));
                    }
                    @include breakpoint (small down)  {
                        transform: scale(1.75) translate(rem-calc(10),rem-calc(-10));
                    }
                }}
            }
            &_name{
                height: max-content;
                position: absolute;
                right: rem-calc(-20);
                z-index: 5;
                padding: rem-calc(16 4 3 4);
                background: linear-gradient(to right,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1));
                @include breakpoint (large)  {
                    opacity: 0;
                    transition: 0.4s;
                }
                @include breakpoint (medium only)  {
                    padding: rem-calc(13 4 0 4);
                    right: rem-calc(-10);
                }
                @include breakpoint(small down){
                    padding: rem-calc(8 3 0 2);
                    right: rem-calc(0);
                    width: rem-calc(20.8);
                }
                &::after{
                    content: '';
                    position: absolute;
                    transition: 0.6s;
                    background-color: rgba($white,1);
                    width: calc(100% - 10px);
                    height: calc(100% - 10px);
                    left: rem-calc(5);
                    top: rem-calc(5);
                    z-index: 2;
                    @include breakpoint(small down){
                        width: calc(100% - 4px);
                        height: calc(100% - 4px);
                        left: rem-calc(2);
                        top: rem-calc(2);
                        display: block;
                    }
                }
                h4{
                    writing-mode: vertical-rl;
                    font-size: rem-calc(32);
                    letter-spacing: rem-calc(10);
                    font-weight: 700;
                    position: relative;
                    z-index: 3;
                    @include breakpoint (xlarge only,large only)  {
                        font-size: rem-calc(26);
                    }
                    @include breakpoint (medium only)  {
                        font-size: rem-calc(22);
                        letter-spacing: rem-calc(6);
                    }
                    @include breakpoint(small down){
                        font-size: rem-calc(12);
                        letter-spacing: rem-calc(2);
                    }
                }
            }
            &_bg{
                width: 100%;
                height: 100%;
                background-size: 1920px 100vh;
                background-position: center;
                z-index: 1;
                -webkit-filter: grayscale(100%); /* Safari */
                filter: grayscale(100%);
                transition: 0.4s;
            }
            &_per{
                position: absolute;
                left: 0;
                z-index: 3;
                transition: 0.4s;
                img{
                    transform: scale(1.5);
                }
            }
            @include breakpoint (large) {
                &:hover{
                    &::before{
                        transform: translateY(rem-calc(-30));
                        opacity: 0;
                        transition: 0.4s;
                    }
                    .pitd-box_bg{
                        -webkit-filter: grayscale(0%); /* Safari */
                        filter: grayscale(0%);
                        transition: 0.4s;
                    }
                    .pitd-box_name{
                        opacity: 1;
                        transition: 0.6s;
                    }
                    .pitd-box_per{
                        transform: translateY(rem-calc(-20));
                        transition: 0.6s;
                    }
                }
            }
            &.is-active{
                &::before{
                    transform: translateY(rem-calc(-30));
                    opacity: 0;
                    transition: 0.4s;
                }
                .pitd-box_bg{
                    -webkit-filter: grayscale(0%); /* Safari */
                    filter: grayscale(0%);
                    transform: scale(1.5);
                    opacity: 0;
                    transition: 1s 0.4s;
                }
            }
        }
    }
    .rdzvbtn{
        @include breakpoint (xlarge only,large only)  {
            margin-top: rem-calc(-60);
        }
        @include breakpoint (medium only)  {
            margin-top: rem-calc(60);
        }
        @include breakpoint (small down)  {
            margin-top: rem-calc(100);
        }
    }
}
// --------------------------------------------------------
// /* protagonist 主角介紹 */ --------------------------------[End]
// --------------------------------------------------------
// --------------------------------------------------------
// /* Comics 漫畫集數 */ --------------------------------[Start]
// --------------------------------------------------------
.in-comic{
    z-index: 21;
    margin-top: rem-calc(-160);
    &-bg{
        position: absolute;
        top: rem-calc(-100);
        left: rem-calc(-200);
        width: calc(100% + 400px);
        height: 80vh;
        max-height: rem-calc(1500);
        min-height: rem-calc(600);
        transform: rotate(-16deg);
        overflow: hidden;
        @include breakpoint (xlarge only)  {
            max-height: rem-calc(600);
        }
        @include breakpoint (large only)  {
            max-height: rem-calc(600);
        }
        @include breakpoint (medium only)  {
            max-height: rem-calc(600);
        }
        &_ctr{
            width: calc(100% + 400px);
            height: calc(100% + 800px);
            position: absolute;
            top: rem-calc(-400);
            left: rem-calc(-200);
            transform: rotate(16deg);
            background-image: url('../images/index/k_v_bn.jpg');
            background-repeat: repeat-y;
            background-size: 100% auto;
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                background-color: rgba($black,0.92);
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    .comic-ctr{
        display: flex;
        flex-wrap: wrap;
        z-index: 9;
        @include breakpoint (small down)  {
            max-width: rem-calc(345);
        }
        .pcsubtitle{
            color: rgba($white,1);
        }
        .comiccard{
            width: 100%;
            margin-top: rem-calc(80);
            @include breakpoint (large only)  {
                margin-top: rem-calc(60);
            }
            .swiper-arrow{
                top: calc(50% - 20px);
                &.prev{
                    left: rem-calc(10);
                    @include breakpoint (xxlarge)  {
                        left: rem-calc(350);
                    }
                    @include breakpoint (xlarge)  {
                        left: rem-calc(250);
                    }
                    @include breakpoint (large)  {
                        left: rem-calc(160);
                    }
                    @include breakpoint (medium only)  {
                        left: rem-calc(60);
                    }
                }
                &.next{
                    right: rem-calc(10);
                    transform: rotate(180deg);
                    @include breakpoint (xxlarge)  {
                        right: rem-calc(350);
                    }
                    @include breakpoint (xlarge)  {
                        right: rem-calc(250);
                    }
                    @include breakpoint (large)  {
                        right: rem-calc(160);
                    }
                    @include breakpoint (medium only)  {
                        right: rem-calc(60);
                    }
                }
            }
            .cocbox{
                opacity: 0;
                transition: 0.4s;
                &.swiper-slide-prev{
                    transform: translate(rem-calc(-40),rem-calc(58)) !important;
                    opacity: 1;
                    transition: 0.4s;
                    @include breakpoint (large only)  {
                        transform: translate(rem-calc(-30),rem-calc(39)) !important;
                    }
                    @include breakpoint (medium only)  {
                        transform: translate(rem-calc(-30),rem-calc(42)) !important;
                    }
                    @include breakpoint (small down)  {
                        transform: translate(rem-calc(-30),rem-calc(44)) !important;
                    }
                    &::before{
                        opacity: 1;
                        transition: 0.4s;
                    }
                    .cocbox-img{
                        &::before{
                            opacity: 1;
                            transition: 0.4s;
                        }
                    }
                }
                &:not(.swiper-slide-active){
                    transform: translate(rem-calc(40),rem-calc(58));
                    transition: 0.4s;
                    @include breakpoint (large only)  {
                        transform: translate(rem-calc(30),rem-calc(39));
                    }
                    @include breakpoint (medium only)  {
                        transform: translate(rem-calc(30),rem-calc(42));
                    }
                    @include breakpoint (small down)  {
                        transform: translate(rem-calc(30),rem-calc(44));
                    }
                }
                &.swiper-slide-active{
                    opacity: 1;
                    transition: 0.4s;
                    + div{
                        opacity: 1;
                        transition: 0.4s;
                        + div{
                            opacity: 1;
                            transition: 0.4s;
                            + div{
                                opacity: 1;
                                transition: 0.4s;
                            }
                        }
                    }
                    .cocbox-img{
                        transform: scale(1.3);
                        transition: 0.4s;
                    }
                    .cocbox-title{
                        transform: translateX(rem-calc(0));
                        &_nbr{
                            transform: translateY(rem-calc(0));
                            opacity: 1;
                            transition: 0.6s;
                        }
                        &_name{
                            opacity: 1;
                            transition: 0.6s 0.4s;
                        }
                    }
                }
                &-img{
                    position: relative;
                    &::before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: rgba($black,0.6);
                        opacity: 0;
                        transition: 0.4s;
                    }
                }
                &-title{
                    position: absolute;
                    bottom: 0%;
                    left: rem-calc(-137);
                    transform: translateX(rem-calc(-50));
                    @include breakpoint (xlarge only,large only,medium only)  {
                        left: rem-calc(-127);
                    }
                    @include breakpoint (small down)  {
                        bottom: -7%;
                        left: rem-calc(0);
                        transform: translateX(rem-calc(0));
                    }
                    &_nbr{
                        position: relative;
                        z-index: 1;
                        color: rgba($white,1);
                        font-size: rem-calc(60);
                        transform: translateY(rem-calc(10));
                        opacity: 0;
                        transition: 0.2s;
                    }
                    &_name{
                        position: relative;
                        z-index: 3;
                        background-color: rgba($white,0.5);
                        backdrop-filter: blur(5px);
                        margin-top: rem-calc(-40);
                        padding: rem-calc(20 50);
                        opacity: 0;
                        transition: 0.2s;
                        @include breakpoint (xlarge only,large only,medium only)  {
                            padding: rem-calc(20 35);
                        }
                        @include breakpoint (small down)  {
                            padding: rem-calc(14 20);
                        }
                        .rdzvbtn{
                            min-width: rem-calc(230);
                            margin-top: rem-calc(20);
                            @include breakpoint (xlarge only,large only,medium only)  {
                                min-width: rem-calc(200);
                                margin-top: rem-calc(10);
                            }
                            @include breakpoint (small down)  {
                                min-width: inherit;
                                width: 100%;
                            }
                            &-ctr{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .cc-arrow{
        // background-color: rgba($black,0.3);
        width: calc(50% - 220px);
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 10;
        display: none;
        @include breakpoint (xlarge only)  {
            width: calc(50% - 160px);
        }
        @include breakpoint (large only)  {
            width: calc(50% - 160px);
        }
        @include breakpoint (medium only)  {
            width: calc(50% - 160px);
        }
        &.prev{
            left: 0;
            &:hover {
                cursor: url('../images/sld_arrow-big.svg') 100 100,auto;
            }
        }
        &.next{
            right: 0;
            &:hover{
                cursor:url('../images/sld_arrow-big_r.svg') 100 100,auto;
            }
        }
        &.swiper-button-disabled{
            width: 0;
        }
    }
}
.in-comic{
    .comic-ctr{
        .in-title{
            opacity: 0;
            transition: 0.4s;
        }
        .pcsubtitle{
            opacity: 0;
            transform: scale(0.8);
            transition: 0.4s;
        }
        .comiccard{
            opacity: 0;
            transform: scale(1.2);
            transition: 0.4s;
        }
    }
    &.hover{
        .comic-ctr{
            .in-title{
                opacity: 1;
                transition: 0.4s 0.8s;
            }
            .pcsubtitle{
                opacity: 1;
                transform: scale(1);
                transition: 0.6s 1s;
            }
            .comiccard{
                opacity: 1;
                transform: scale(1);
                transition: 0.6s 0.4s;
            }
        }
    }
}
// --------------------------------------------------------
// /* Comics 漫畫集數 */ --------------------------------[End]
// --------------------------------------------------------
// --------------------------------------------------------
// /* Videos */ --------------------------------[Start]
// --------------------------------------------------------
.in-video{
    padding-top: rem-calc(600);
    margin-top: rem-calc(-600);
    background-image: url('../images/bg-pic_line.jpg');
    background-repeat: repeat;
    background-size: 200px 200px;
    background-attachment: fixed;
    z-index: 18;
    @include breakpoint (xxlarge only)  {
        margin-top: rem-calc(-500);
    }
    @include breakpoint (xlarge only)  {
        padding-top: rem-calc(400);
        margin-top: rem-calc(-300);
    }
    @include breakpoint (large only,medium only)  {
        padding-top: rem-calc(400);
        margin-top: rem-calc(-240);
        padding-bottom: rem-calc(40);
    }
    @include breakpoint (small down)  {
        margin-top: rem-calc(-500);
    }
    .videocard-arrow{
        position: absolute;
        top: 0;
        z-index: 99;
        height: 100%;
        background-color: rgba($black,0);
        width: calc((100% - 960px) / 2);
        @include breakpoint (xlarge only)  {
            width: calc((100% - 743px) / 2);
        }
        @include breakpoint (large only)  {
            width: calc((100% - 608px) / 2);
        }
        @include breakpoint (medium only)  {
            width: calc((100% - 472px) / 2);
        }
        @include breakpoint (small only)  {
            width: calc((100% - 282px) / 2);
        }
        &.prev{
            left: 0;
        }
        &.next{
            right: 0;
        }
    }
    .video-ctr{
        display: flex;
        flex-wrap: wrap;
    }
    .videocard{
        width: 100%;
        margin: rem-calc(100 0 150 0);
        @include breakpoint (medium only)  {
            margin: rem-calc(70 0 120 0);
        }
        @include breakpoint (small down)  {
            margin: rem-calc(50 0 120 0);
        }
        .vdobox{
            z-index: 8;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            &.swiper-slide-active{
                box-shadow: 0 0 20px rgba($black,0.6);
                transform: scale(1.35);
                z-index: 9;
                transition: 0.4s;
                &::before{
                    opacity: 0;
                    transition: 0s;
                }
                &::after{
                    opacity: 1;
                    transition: 0.4s 0.6s;
                }
                .vdobox-img{
                    &::before{
                        opacity: 0;
                        transition: 0.4s;
                    }
                    img{
                        -webkit-filter: grayscale(0%); /* Safari */
                        filter: grayscale(0%);
                        transition: 0.4s 0.4s;
                    }
                }
                .vdobox-name{
                    opacity: 1;
                    transition: 0.4s 0.6s;
                }
                .vdobox-btn{
                    transform: scale(1);
                    opacity: 1;
                    transition: 0.4s 0.6s;
                }
            }
            &.swiper-slide-prev{
                perspective: 50rem;
                .vdobox-img{
                    transform: rotateY(10deg) translateZ(-30px) scale(1);
                }
            }
            &.swiper-slide-next{
                perspective: 50rem;
                .vdobox-img{
                    transform: rotateY(-10deg) translateZ(30px) scale(1);
                }
            }
            &::after,&::before{
                content: '';
                width: 200%;
                height: 100%;
                position: absolute;
                bottom: 0%;
                left: -50%;
                background-size: 100% 100%;
                z-index: 1;
                opacity: 0;
                transition: 0.2s;
                @include breakpoint (medium down)  {
                    height: rem-calc(50);
                }
            }
            &::after{background: radial-gradient(ellipse at 50% 5%,rgba($black,0),rgba($black,0),rgba($black,0.4)50%,rgba($black,1)70%);}
            &::before{background: radial-gradient(ellipse at 50% 5%,rgba($white,0.5),rgba($gr-ye,0.5),rgba($gr-or,0.5),rgba($gr-pi,1)70%);}
            &-img{
                position: relative;
                &::before{
                    content: '';
                    width: 100%;
                    height: 100%;
                    background-color: rgba($black,0.6);
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 1;
                    transition: 0.4s;
                    z-index: 5;
                }
                img{
                    -webkit-filter: grayscale(40%); /* Safari */
                    filter: grayscale(40%);
                    transition: 0.4s;
                }
            }
            &-name{
                width: 100%;
                font-size: rem-calc(14);
                opacity: 0;
                transition: 0.4s;
                z-index: 9;
                position: absolute;
                bottom: 0;
                left: 0;
                color: rgba($white,1);

                line-height: 200%;
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -moz-line-clamp: 1;
                -ms-line-clamp: 1;
                -webkit-box-orient: vertical;
                @include breakpoint (medium down)  {
                    font-size: rem-calc(12);
                    margin-top: rem-calc(5);
                    margin-bottom: rem-calc(0);
                    transform: scale(0.8);
                }
            }
            &-btn{
                width: rem-calc(100);
                height: rem-calc(100);
                position: absolute;
                left: calc(50% - 50px);
                top: calc(50% - 50px);
                background-image: url('../images/sld_arrow-play.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                z-index: 8;
                transform: scale(0.7);
                opacity: 0;
                transition: 0.4s;
                @include breakpoint (medium down)  {
                    width: rem-calc(50);
                    height: rem-calc(50);
                    left: calc(50% - 25px);
                    top: calc(50% - 25px);
                }
                &-ctr{
                    width: 100%;
                    height: 100%;
                    background-image: url('../images/sld_arrow-bigc.svg');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    transition: 0.2s;
                }
            }
            @include breakpoint (large) {
                &:hover{
                    .vdobox-btn{
                        &-ctr{
                            transform: rotate(720deg);
                            transition: 30s;
                        }
                    }
                }
                &.swiper-slide-active{
                    &:hover{
                        &::after,&::before{
                            transition: 0.6s;
                        }
                        &::after{opacity: 0;}
                        &::before{opacity: 1;}
                    }
                }
            }
        }
        .swiper-pagination{
            transform: translateY(rem-calc(70));
            bottom: initial !important;
            @include breakpoint (medium only)  {
                transform: translateY(rem-calc(60));
            }
            @include breakpoint (small down)  {
                transform: translateY(rem-calc(50));
            }
        }
    }
}
.in-video{
    .video-ctr{
        .in-title{
            opacity: 0;
            transition: 0.4s;
        }
        .pcsubtitle,.rdzvbtn{
            opacity: 0;
            transform: scale(0.8);
            transition: 0.4s;
        }
        .videocard{
            opacity: 0;
            transform: scale(1.2);
            transition: 0.4s;
        }
    }
    &.hover{
        .in-title{
            opacity: 1;
            transition: 0.6s 0.8s;
        }
        .pcsubtitle,.rdzvbtn{
            opacity: 1;
            transform: scale(1);
            transition: 0.4s 1s;
        }
        .videocard{
            opacity: 1;
            transform: scale(1);
            transition: 0.6s 0.4s;
        }
    }
}
// --------------------------------------------------------
// /* Videos */ --------------------------------[End]
// --------------------------------------------------------
// --------------------------------------------------------
// /* merch 週邊商品 */ --------------------------------[Start]
// --------------------------------------------------------
.in-merch{
    background-image: url('../images/bg-pic_line.jpg');
    background-repeat: repeat;
    background-size: 200px 200px;
    background-attachment: fixed;
    z-index: 17;
    @include breakpoint (medium down)  {
        padding-top: rem-calc(30);
    }
    @include breakpoint (small down)  {
        padding: rem-calc(80 0 80 0);
    }
    .merch-ctr{
        display: flex;
        flex-wrap: wrap;
    }
    .merchcont{
        width: 100%;
        max-width: rem-calc(1000);
        margin: rem-calc(50) auto;
        @include breakpoint (xlarge only)  {
            max-width: rem-calc(800);
        }
        @include breakpoint (medium down)  {
            max-width: rem-calc(550);
        }
        @include breakpoint (small down)  {
            margin: rem-calc(20) auto;
        }
        &-box{
            width: calc(100% / 4 - 30px);
            margin: rem-calc(0 15);
            @include breakpoint (xlarge only)  {
                width: calc(100% / 4 - 20px);
                margin: rem-calc(0 10);
            }
            @include breakpoint (medium down)  {
                width: calc(100% / 4 - 10px);
                margin: rem-calc(0 5);
            }
            @include breakpoint (small down)  {
                width: calc(100% / 2 - 10px);
                margin: rem-calc(0 5);
            }
            .mbi{
                position: relative;
                &-ctr{
                    position: relative;
                    background: linear-gradient(-45deg,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1));
                    overflow: hidden;
                    &-pic{
                        background-color: rgba($white,1);
                        transform: scale(1);
                        transition: 0.4s;
                        img{
                            width: 100%;
                        }
                    }
                }
                &-cube{
                    width: rem-calc(20);
                    position: absolute;
                    left: calc(50% - 10px);
                    bottom: rem-calc(-8);
                    transform: rotate(80deg);
                    opacity: 0;
                    transition: 0.4s;
                }
            }
            &-text{
                font-size: rem-calc(16);
                line-height: 160%;
                margin-top: rem-calc(10);
                color: rgba($black,1);
            
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -moz-line-clamp: 2;
                -ms-line-clamp: 2;
                -webkit-box-orient: vertical;
                @include breakpoint (xlarge down)  {
                    font-size: rem-calc(14);
                }
            }
            @include breakpoint (large) {
                &:hover{
                    .mbi{
                        .mbi-ctr{
                            &-pic{
                                transform: scale(0.96);
                                transition: 0.4s;
                            }
                        }
                        .mbi-cube{
                            transform: rotate(0deg);
                            opacity: 1;
                            transition: 0.4s;
                        }
                    }
                }
            }
        }
    }
}
.in-merch{
    .merch-ctr{
        .in-title{
            opacity: 0;
            transition: 0.4s;
        }
        .pcsubtitle,.rdzvbtn{
            opacity: 0;
            transform: scale(0.8);
            transition: 0.4s;
        }
        .merchcont{
            opacity: 0;
            transform: scale(1.2);
            transition: 0.4s;
        }
    }
    &.hover{
        .in-title{
            opacity: 1;
            transition: 0.6s 0.8s;
        }
        .pcsubtitle,.rdzvbtn{
            opacity: 1;
            transform: scale(1);
            transition: 0.4s 1s;
        }
        .merchcont{
            opacity: 1;
            transform: scale(1);
            transition: 0.6s 0.4s;
        }
    }
}
// --------------------------------------------------------
// /* merch 週邊商品 */ --------------------------------[End]
// --------------------------------------------------------